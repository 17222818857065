// Валидация широты (должна быть от -90 до 90)
export const validateLat = (value) => {
  if (value === undefined || value === null) {
      return 'Широта обязательна';
  }
  if (isNaN(value)) {
      return 'Широта должна быть числом';
  }
  if (value < -90 || value > 90) {
      return 'Широта должна быть в диапазоне от -90 до 90';
  }
  return;
};

// Валидация долготы (должна быть от -180 до 180)
export const validateLon = (value) => {
  if (value === undefined || value === null) {
      return 'Долгота обязательна';
  }
  if (isNaN(value)) {
      return 'Долгота должна быть числом';
  }
  if (value < -180 || value > 180) {
      return 'Долгота должна быть в диапазоне от -180 до 180';
  }
  return;
};