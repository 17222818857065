import React from 'react';
import {
    CardActions,
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    List,
    number,
    NumberInput,
    ReferenceField,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isGeEmployee,
    isPartnerService,
    ToolbarSaveAllDeleteAdmin,
} from './utils';
import CloseSession from './closeSession/CloseSession';

const statuses = [
    { id: 0, name: 'Сессия в процессе старта' },
    { id: 1, name: 'Сессия успешно открыта' },
    { id: 2, name: 'Сессия закрыта при возврате устройства' },
    { id: 3, name: 'Сессия не начата: проблемы со станцией' },
    { id: 4, name: 'Сессия закрыта партнером Getenergy' },
];

const SessionActions = ({ data, permissions, resource }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {(isGeEmployee(permissions) || isPartnerService(permissions)) && (
            <CloseSession
                record={data}
                resource={resource}
            />
        )}
    </CardActions>
);

const SessionsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id сессии' },
                { id: 'partner_id', name: 'Id партнера' },
                { id: 'dno', name: 'Id пб' },
                { id: 'status', name: 'Статус' },
                { id: 'step', name: 'Статус код' },
                { id: 'paid_suc', name: 'платежи' },
                { id: 'paid_unsuc', name: 'Статус код' },
                //
                { id: 'location_start', name: 'Начальная локация' },
                { id: 'sno_start', name: 'Начальная станция' },
                { id: 'start_time', name: 'Время начала' },
                //
                { id: 'location_end', name: 'Конечная локация' },
                { id: 'sno_end', name: 'Конечная станция' },
                { id: 'close_time', name: 'Время завершения' },
                { id: 'pb_return_time', name: 'Время возвращения пб' },
                //
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
        <DateTimeInput
            source="after"
            label="Сессии после"
            options={{ format: 'dd.MM.YYYY HH:mm' }}
        />
        <DateTimeInput
            source="before"
            label="Сессии до"
            options={{ format: 'dd.MM.YYYY HH:mm' }}
        />
        {/* <BooleanInput source="first_session" label="Первая сессия"/> */}
    </Filter>
);

export const PartnerServiceSessionsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<SessionsFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={permissions === 'partner_service' ? 'show' : 'edit'}>
            <TextField
                label="Id сессии"
                source="id"
            />
            <TextField
                label="Id партнера"
                source="partner_id"
            />
            <TextField
                label="Dno"
                source="dno"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Успешные платежи"
                source="paid_suc"
            />
            <TextField
                label="Неуспешные платежи"
                source="paid_unsuc"
            />
            <TextField
                label="Статус код"
                source="step"
            />
            {/*  */}
            <TextField
                label="Id стартовой локации"
                source="location_start"
            />
            <ReferenceField
                label="Стартовая локация"
                source="location_start"
                reference="locations"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Адрес стартовой локации"
                source="location_start"
                reference="locations"
                linkType={false}
                allowEmpty
            >
                <TextField source="address" />
            </ReferenceField>
            <TextField
                label="Начальная станция"
                source="sno_start"
            />
            <DateField
                label="Время начала"
                source="start_time"
                showTime
                locales="ru-RU"
            />
            {/*  */}
            <TextField
                label="Id конечная локации"
                source="location_end"
            />
            <ReferenceField
                label="Конечная локация"
                source="location_end"
                reference="locations"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Адрес конечной локации"
                source="location_end"
                reference="locations"
                linkType={false}
                allowEmpty
            >
                <TextField source="address" />
            </ReferenceField>
            <TextField
                label="Конечная станция"
                source="sno_end"
            />
            <DateField
                label="Время завершения"
                source="close_time"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время возвращения пб"
                source="pb_return_time"
                showTime
                locales="ru-RU"
            />
            {/*  */}
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddServiceSession = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <NumberInput
                label="Id партнера"
                source="partner_id"
                validate={number()}
            />
            <TextInput
                label="Dno"
                source="dno"
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'preparation', name: 'preparation' },
                    { id: 'active', name: 'active' },
                    { id: 'closed', name: 'closed' },
                ]}
                validate={required()}
            />
            <NumberInput
                label="Успешные платежи"
                source="paid_suc"
                validate={number()}
            />
            <NumberInput
                label="Неуспешные платежи"
                source="paid_unsuc"
                validate={number()}
            />
            <SelectInput
                label="Статус код"
                source="step"
                choices={statuses}
                validate={required()}
            />
            {/*  */}
            <NumberInput
                label="Начальная локация"
                source="location_start"
            />
            <TextInput
                label="Начальная станция"
                source="sno_start"
            />
            <DateTimeInput
                label="Время начала"
                source="start_time"
            />
            {/*  */}
            <NumberInput
                label="Конечная локация"
                source="location_end"
            />
            <TextInput
                label="Конечная станция"
                source="sno_end"
            />
            <DateTimeInput
                label="Время завершения"
                source="close_time"
            />
            <DateTimeInput
                label="Время возвращения пб"
                source="pb_return_time"
            />
        </SimpleForm>
    </Create>
);

export const EditServiceSession = ({ permissions, ...props }) => (
    <Edit
        {...props}
        submitOnEnter={false}
        actions={<SessionActions permissions={permissions} />}
    >
        <SimpleForm
            submitOnEnter={false}
            toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}
        >
            <NumberInput
                label="Id сессии"
                source="id"
                validate={number()}
                disabled
            />
            <NumberInput
                label="Id партнера"
                source="partner_id"
                validate={number()}
                disabled
            />
            <TextInput
                label="Dno"
                source="dno"
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'preparation', name: 'preparation' },
                    { id: 'active', name: 'active' },
                    { id: 'closed', name: 'closed' },
                ]}
                validate={required()}
            />
            <NumberInput
                disabled
                label="Успешные платежи"
                source="paid_suc"
            />
            <NumberInput
                disabled
                label="Неуспешные платежи"
                source="paid_unsuc"
            />
            <SelectInput
                label="Статус код"
                source="step"
                choices={statuses}
                validate={required()}
            />
            {/*  */}
            <NumberInput
                label="Начальная локация"
                source="location_start"
            />
            <TextInput
                label="Начальная станция"
                source="sno_start"
            />
            <DateTimeInput
                label="Время начала"
                source="start_time"
                options={{ format: 'dd.MM.YYYY HH:mm' }}
            />
            {/*  */}
            <NumberInput
                label="Конечная локация"
                source="location_end"
            />
            <TextInput
                label="Конечная станция"
                source="sno_end"
            />
            <DateTimeInput
                label="Время завершения"
                source="close_time"
                options={{ format: 'dd.MM.YYYY HH:mm' }}
            />
            <DateTimeInput
                label="Время возвращения пб"
                source="pb_return_time"
                options={{ format: 'dd.MM.YYYY HH:mm' }}
            />
        </SimpleForm>
    </Edit>
);

export const ShowServiceSession = ({ permissions, ...props }) => (
    <Show
        {...props}
        submitOnEnter={false}
        actions={<SessionActions permissions={permissions} />}
    >
        <SimpleShowLayout>
            <TextField
                label="Id сессии"
                source="id"
            />
            <TextField
                label="Id партнера"
                source="partner_id"
            />
            <TextField
                label="Dno"
                source="dno"
            />
            <SelectField
                label="Статус"
                source="status"
                choices={[
                    { id: 'preparation', name: 'preparation' },
                    { id: 'active', name: 'active' },
                    { id: 'closed', name: 'closed' },
                ]}
            />
            <SelectField
                label="Статус код"
                source="step"
                choices={statuses}
            />
            <TextField
                label="Успешные платежи"
                source="paid_suc"
            />
            <TextField
                label="Неуспешные платежи"
                source="paid_unsuc"
            />
            <TextField
                label="Неуспешные платежи"
                source="paid_unsuc"
            />
            {/*  */}
            <TextField
                label="Начальная локация"
                source="location_start"
            />
            <TextField
                label="Начальная станция"
                source="sno_start"
            />
            <DateField
                label="Время начала"
                source="start_time"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Конечная локация"
                source="location_end"
            />
            <TextField
                label="Конечная станция"
                source="sno_end"
            />
            <DateField
                label="Время завершения"
                source="close_time"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время возвращения пб"
                source="pb_return_time"
                showTime
                locales="ru-RU"
            />
        </SimpleShowLayout>
    </Show>
);
