import { Filter, SelectInput, TextInput } from 'react-admin';
import { isAdmin, isEmployee, isPartner, isPartnerService } from '../utils';
import React from 'react';

const LocationsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id локации' },
                { id: 'contract_id', name: 'Id договора с партнером' },
                ...(isAdmin(props.permissions) || isPartnerService(props.permissions)
                    ? [
                          {
                              id: 'partner_service_contract_id',
                              name: 'Id договора c сервисным партнером',
                          },
                      ]
                    : []),
                { id: 'partner_name', name: 'Партнер' },
                ...(isAdmin(props.permissions) || isPartnerService(props.permissions)
                    ? [{ id: 'partner_service_name', name: 'Сервисный партнер' }]
                    : []),
                ...(isEmployee(props.permissions)
                    ? [{ id: 'manager_contract_id', name: 'Id договора c менеджером' }]
                    : []),    
                ...((isEmployee(props.permissions) || isPartner(props.permissions))
                    ? [{ id: 'manager_name', name: 'Менеджер' }]
                    : []),
                ...(!isPartner(props.permissions)              
                    ? [{ id: 'deposit_feature', name: 'Депозит' }]
                    : []),
                { id: 'name', name: 'Название' },
                { id: 'address', name: 'Адрес' },
                { id: 'city', name: 'Город' },
                { id: 'sno', name: 'Станция' },
                ...(!isPartner(props.permissions)              
                    ? [{ id: 'holder_prefix', name: 'Префикс' }]
                    : []),
                { id: 'holder_short_number', name: 'Короткий номер станции' },
                { id: 'category', name: 'Категория' },
                { id: 'type', name: 'Тип' },
                { id: 'latitude', name: 'Широта' },
                { id: 'longitude', name: 'Долгота' },
                { id: 'start_daytime', name: 'Начало работы' },
                { id: 'end_daytime', name: 'Конец работы' },
                { id: 'work_duration', name: 'Время работы' },
                { id: 'guide', name: 'Гайд' },
                ...(!isPartner(props.permissions)                 
                    ? [{ id: 'comment', name: 'Комментарии' }]
                    : []),
                { id: 'status', name: 'Статус' },
                { id: 'visibility', name: 'Видимость на карте' },
                ...(!isPartner(props.permissions)
                    ? [{ id: 'created', name: 'Время создания' }]
                    : []),
                ...(!isPartner(props.permissions)
                    ? [{ id: 'updated', name: 'Последнее обновление' }]
                    : []),
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export default LocationsFilter;
