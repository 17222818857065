import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Close from '@material-ui/icons/Close';
import './styles.css';

const actionCreator = (day, payload) => ({
    type: '@@redux-form/CHANGE',
    meta: {
        form: 'record-form',
        field: `schedule.${day}`,
        touch: false,
        persistentSubmitErrors: false,
    },
    payload,
});

const initialState = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
};

const CopyScheduleModal = props => {
    const { isOpen, setIsOpen, currentDay, daysOfWeek, dispatch, schedule } = props;
    const currentSchedule = schedule[currentDay];
    const [copy, setCopy] = useState(initialState);
    const handleClick = () => {
        setIsOpen(false);
        setCopy(initialState);
    };

    const handleSaveClick = () => {
        Object.keys(copy)
            .filter(key => copy[key])
            .forEach(key => {
                dispatch(actionCreator(key, currentSchedule));
            });

        handleClick();
    };
    return (
        <Modal
            open={isOpen}
            onBackdropClick={handleClick}
        >
            <div className={'copy-modal'}>
                <Card className={'copy-modal__card'}>
                    <div className={'copy-modal__close-btn'}>
                        <Button onClick={handleClick}>
                            <Close />
                        </Button>
                    </div>
                    {daysOfWeek.map(
                        (day, index) =>
                            index + 1 !== currentDay && (
                                <FormControlLabel
                                    checked={copy[index + 1]}
                                    onChange={() => {
                                        setCopy(prev => ({
                                            ...prev,
                                            [index + 1]: !prev[index + 1],
                                        }));
                                    }}
                                    control={<Checkbox value={day} />}
                                    label={day}
                                />
                            )
                    )}
                    <div className={'copy-modal__btn-row'}>
                        <div className={'copy-modal__btn-row-inner'}>
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={handleClick}
                            >
                                Отменить
                            </Button>
                            <Button
                                onClick={handleSaveClick}
                                color={'primary'}
                                variant={'contained'}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Modal>
    );
};

export default connect((state, dispatch) => ({
    schedule: state.form['record-form'].values.schedule,
    dispatch,
}))(CopyScheduleModal);
