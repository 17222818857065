import React from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    Edit,
    Filter,
    Labeled,
    List,
    NumberInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    UrlField,
} from 'react-admin';
import ReturnButton from './orderButton/ReturnButton';
import RepayButton from './orderButton/RepayButton';
import { CardActions } from '@material-ui/core';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isGeEmployee,
    ToolbarSaveAllDeleteAdmin,
} from './utils';

const OrderActions = ({ data, permissions }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {isGeEmployee(permissions) && <ReturnButton record={data} />}
        {isGeEmployee(permissions) && <RepayButton record={data} />}
    </CardActions>
);
const RecalculationField = ({ record, ...props }) => {
    if (record.type === 'deposit') {
        return (
            <Labeled label="Перезачтенные заказы">
                <ArrayField
                    source="recalculated_orders"
                    record={record}
                >
                    <Datagrid
                        rowClick={recStr => {
                            const rec = JSON.parse(recStr);
                            return `/orders/${rec.id}/show`;
                        }}
                    >
                        >
                        <TextField
                            label="Id"
                            source="id"
                        />
                        <TextField
                            label="Тип"
                            source="type"
                        />
                        <TextField
                            label="Шаг"
                            source="step"
                        />
                        <TextField
                            label="Сумма"
                            source="amount"
                        />
                        <TextField
                            label="Статус"
                            source="status"
                        />
                        <DateField
                            label="Время зачета"
                            source="payment_date"
                            showTime
                            locales="ru-RU"
                        />
                    </Datagrid>
                </ArrayField>
            </Labeled>
        );
    } else if (record.recalculating_order_id) {
        return (
            <Labeled label="Перезачтен заказом">
                <a href={`#/orders/${record.recalculating_order_id}/show`}>
                    {record.recalculating_order_id}
                    <span></span>
                </a>
            </Labeled>
        );
    } else {
        return null;
    }
};

const OrdersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id заказа' },
                { id: 'user_id', name: 'Id юзера' },
                { id: 'amount', name: 'Сумма заказа' },
                { id: 'session_id', name: 'Id сессии' },
                { id: 'type', name: 'Тип заказа' },
                { id: 'status', name: 'Статус' },
                { id: 'recalculating_order_id', name: 'Id заказа перезачета' },
                { id: 'comment', name: 'Комментарий' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const OrdersList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<OrdersFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="show">
            <TextField
                label="Id заказа"
                source="id"
            />
            <TextField
                label="Id юзера"
                source="user_id"
            />
            <TextField
                label="Сумма заказа"
                source="amount"
            />
            <TextField
                label="Оплаченная сумма"
                source="amount_paid"
            />
            <TextField
                label="Валюта"
                source="currency"
            />
            <TextField
                label="Id сессии"
                source="session_id"
            />
            <TextField
                label="Тип заказа"
                source="type"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <DateField
                label="Время захвата"
                source="capture_date"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Платежный метод"
                source="payment_method"
            />
            <TextField
                label="Шаг"
                source="step"
            />
            {/*<ReferenceField label="Перезачет" source="recalculating_order_id" reference="orders" allowEmpty />*/}
            <TextField
                label="Комментарий"
                source="comment"
            />
            <TextField
                label="Процент бонуса"
                source="bonus_percent"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const EditOrder = ({ permissions, ...props }) => (
    <Edit
        {...props}
        submitOnEnter={false}
        actions={<OrderActions permissions={permissions} />}
    >
        <SimpleForm
            submitOnEnter={false}
            toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}
        >
            <TextInput
                disabled
                label="Id заказа"
                source="id"
            />
            <NumberInput
                label="Id юзера"
                source="user_id"
            />
            <NumberInput
                label="Сумма заказа"
                source="amount"
            />
            <NumberInput
                label="Оплаченная сумма"
                source="amount_paid"
            />
            <NumberInput
                label="Id сессии"
                source="session_id"
            />
            <TextInput
                label="Тип заказа"
                source="type"
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'held', name: 'held' },
                    { id: 'paid', name: 'paid' },
                    { id: 'unpaid', name: 'unpaid' },
                    { id: 'voided', name: 'voided' },
                    { id: 'returned_suc', name: 'returned_suc' },
                    { id: 'failed', name: 'failed' },
                    { id: 'old_unpaid', name: 'old_unpaid' },
                ]}
            />
            <TextInput
                disabled
                label="Время захвата"
                source="capture_date"
            />
            <TextInput
                disabled
                label="Платежный метод"
                source="payment_method"
            />
            <TextInput
                label="Комментарий"
                source="comment"
            />
            <TextInput
                label="Процент бонуса"
                source="bonus_percent"
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
const ReceiptField = ({ record, source, label }) => {
    if (record.payment === 'cloudpayments') {
        const fakeRecord = {
            [source]: `https://receipts.ru/${record[source]}`,
        };
        return (
            <Labeled label={label}>
                {record[source] ? (
                    <UrlField
                        source={source}
                        record={fakeRecord}
                    />
                ) : null}
            </Labeled>
        );
    }
    return null;
};
export const ShowOrder = ({ permissions, ...props }) => (
    <Show
        {...props}
        submitOnEnter={false}
        actions={<OrderActions permissions={permissions} />}
    >
        <SimpleShowLayout>
            <TextField
                disabled
                label="Id заказа"
                source="id"
            />
            <TextField
                label="Id юзера"
                source="user_id"
            />
            <TextField
                label="Сумма заказа"
                source="amount"
            />
            <TextField
                label="Оплаченная сумма"
                source="amount_paid"
            />
            <TextField
                label="Id сессии"
                source="session_id"
            />
            <TextField
                label="Тип заказа"
                source="type"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <DateField
                label="Время захвата"
                source="capture_date"
                showTime
                locales="ru-RU"
            />
            <TextField
                disabled
                label="Платежный метод"
                source="payment_method"
            />
            <ReceiptField
                label="Чек оплаты"
                source="receipt_id"
            />
            <ReceiptField
                label="Чек возврата"
                source="receipt_return_id"
            />
            <RecalculationField />
            <TextField
                label="Комментарий"
                source="comment"
            />
            <TextField
                label="Процент бонуса"
                source="bonus_percent"
            />
            <DateField
                disabled
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                disabled
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </SimpleShowLayout>
    </Show>
);
