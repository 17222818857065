import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const AllDayCheckbox = ({
    scheduleState,
    setScheduleState,
    index,
    input,
    label,
    timezone,
    disabled = false,
}) => {
    const [prevValue, setPrevValue] = useState(null);
    const handler = e => {
        const { checked } = e.target;
        setScheduleState(prev => ({ ...prev, [index]: { ...prev[index], isAllDay: checked } }));
        if (checked) {
            input.onChange([['00:00' + timezone, '00:00' + timezone]]);
            setPrevValue(input.value);
        } else {
            if (prevValue && prevValue?.length) {
                input.onChange(prevValue);
            } else {
                input.onChange([[]]);
            }
            setPrevValue(null);
        }
    };

    const { isAllDay, isWorking } = scheduleState[index];
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    disabled={!isWorking || disabled}
                    checked={isAllDay}
                    onChange={handler}
                />
            }
        />
    );
};

export default AllDayCheckbox;
