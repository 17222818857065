
import React from 'react';
import {
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    number,
    minValue,
    maxValue
} from 'react-admin';
import { ToolbarSaveAllDeleteAdmin } from '../utils';
import { DateInput } from 'react-admin-date-inputs';
import { withStyles } from '@material-ui/core/styles';
import {
  stylesWide,
} from '../utils';

export const EditManagerContract = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
  <Edit {...props} classes={classes}>
      <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
         <TextInput
            disabled
            label="Id договора менеджера"
            source="id"
            className={classes.wide}
         />
          <ReferenceInput
            perPage={10000}
            label="Имя менеджера"
            source="manager_id"
            reference="managers"
            required
            className={classes.wide}
          >
             <SelectInput optionText={m => `(${m.id}) ${m.name}`} />
          </ReferenceInput>
          <TextInput
            label="Комиссия"
            source="com_cond"
            validate={[required(), number(), minValue(0), maxValue(1)]}
            className={classes.wide}
          />
          <DateInput
              label="Дата заключения договора"
              source="start_date"
              options={{ format: 'dd.MM.YYYY' }}
              validate={[required()]}
              className={classes.wide}
          />
          <DateInput
              label="Дата расторжения договора"
              source="end_date"
              options={{ format: 'dd.MM.YYYY' }}
              require={false}
              className={classes.wide}
          />
          <TextInput
              label="Подробности"
              source="details"
              className={classes.wide}
          />
          <TextInput
            disabled
            label="Время создания"
            source="created"
            className={classes.wide}
          />
          <TextInput
            disabled
            label="Последнее обновление"
            source="updated"
            className={classes.wide}
          />
      </SimpleForm>
  </Edit>
));
